import "@braze/jquery-validate";

const $ = window.$;

function PasswordRule(icon, verifyFunction) {
  this.verifyFunction = verifyFunction;
  this.icon = icon;
}

function regexVerifyFunction(regex) {
  return function (string) {
    return regex.test(string);
  };
}

let lengthRule = new PasswordRule("#password_length_check_icon", regexVerifyFunction(/^.{12,}$/));
let uppercaseRule = new PasswordRule("#password_uppercase_check_icon", regexVerifyFunction(/[A-Z]/));
let lowercaseRule = new PasswordRule("#password_lowercase_check_icon", regexVerifyFunction(/[a-z]/));
let numericRule = new PasswordRule("#password_numeric_check_icon", regexVerifyFunction(/[0-9]/));
let specialRule = new PasswordRule("#password_special_check_icon", regexVerifyFunction(/[!-/:-@[-`{-~]/));
let matchRule = new PasswordRule("#password_match_check_icon", function (password) {
  return password === $("#developer_password_confirmation").val();
});

let passwordRules = [lengthRule, uppercaseRule, lowercaseRule, numericRule, specialRule];
let passwordConfirmationRules = [matchRule];

let ruleFailedIconClass = "far fa-check-circle";
let ruleFailedIconColor = "#a8b3b8";
let confirmationRuleFailedIconClass = "far fa-check-circle";
let confirmationRuleFailedIconColor = "#a8b3b8";
let ruleSucceededIconClass = "fas fa-check-circle";
let ruleSucceededIconColor = "#008760";

let updatePasswordRuleIcons = function () {
  let password = $("#developer_password").val();
  passwordRules.forEach((rule) => {
    let icon = $(rule.icon);
    if (rule.verifyFunction(password)) {
      icon.removeClass(ruleFailedIconClass);
      icon.addClass(ruleSucceededIconClass);
      icon.css("color", ruleSucceededIconColor);
    } else {
      icon.removeClass(ruleSucceededIconClass);
      icon.addClass(ruleFailedIconClass);
      icon.css("color", ruleFailedIconColor);
    }
  });

  passwordConfirmationRules.forEach((rule) => {
    let icon = $(rule.icon);
    if (rule.verifyFunction(password)) {
      icon.removeClass(confirmationRuleFailedIconClass);
      icon.addClass(ruleSucceededIconClass);
      icon.css("color", ruleSucceededIconColor);
    } else {
      icon.removeClass(ruleSucceededIconClass);
      icon.addClass(confirmationRuleFailedIconClass);
      icon.css("color", confirmationRuleFailedIconColor);
    }
  });
};

let showPasswordTooltip = function () {
  $(".password-tooltip-container").css("visibility", "visible");
  $(".righttooltiptext").css("visibility", "visible");
};

let hidePasswordTooltip = function () {
  $(".password-tooltip-container").css("visibility", "hidden");
  $(".righttooltiptext").css("visibility", "hidden");
};

let toggleTooltipErrorIcon = function () {
  passwordRules.forEach((rule) => {
    let icon = $(rule.icon);
    icon.removeClass("far");
  });
  ruleFailedIconClass = "fas fa-times-circle";
  ruleFailedIconColor = "#D32F4C";
  updatePasswordRuleIcons();
};

let toggleConfirmationTooltipErrorIcon = function () {
  passwordConfirmationRules.forEach((rule) => {
    let icon = $(rule.icon);
    icon.removeClass("far");
  });
  confirmationRuleFailedIconClass = "fas fa-times-circle";
  confirmationRuleFailedIconColor = "#D32F4C";
  updatePasswordRuleIcons();
};

let hidePasswordTooltipIfNoErrors = function () {
  let password = $("#developer_password").val();
  let isValid = true;
  passwordRules.forEach((rule) => {
    if (!rule.verifyFunction(password)) {
      isValid = false;
    }
  });
  passwordConfirmationRules.forEach((rule) => {
    if (!rule.verifyFunction(password)) {
      isValid = false;
    }
  });
  if (isValid) {
    hidePasswordTooltip();
  } else {
    showPasswordTooltip();
  }
};

$(".password-form #developer_password").on("input", updatePasswordRuleIcons);
$(".password-form #developer_password").on("focus", showPasswordTooltip);
$(".password-form #developer_password").on("focusout", toggleTooltipErrorIcon);
$(".password-form #developer_password").on("focusout", hidePasswordTooltipIfNoErrors);
$(".password-form #developer_password_confirmation").on("input", updatePasswordRuleIcons);
$(".password-form #developer_password_confirmation").on("focus", showPasswordTooltip);
$(".password-form #developer_password_confirmation").on("focusout", toggleConfirmationTooltipErrorIcon);
$(".password-form #developer_password_confirmation").on("focusout", hidePasswordTooltipIfNoErrors);

$.validator.addMethod("isPasswordValid", function (password) {
  let isValid = true;
  passwordRules.forEach((rule) => {
    if (!rule.verifyFunction(password)) {
      isValid = false;
    }
  });
  return isValid;
});

$(document).ready(function () {
  $(".password-form #edit_developer").validate({
    showErrors: function () {},
    rules: {
      "developer[password]": {
        isPasswordValid: true,
      },
      "developer[password_confirmation]": {
        equalTo: "#developer_password",
      },
      acknowledged_privacy_policy_and_tos: {
        required: true,
      },
    },
  });
});

$("#edit_developer input").on("keyup change paste", function () {
  $("#activate-button").prop("disabled", !$("#edit_developer").valid());
});

let showPassword = false;
let showConfirmPassword = false;

let setPasswordFieldVisibility = function (isVisible, passwordField, icon) {
  if (isVisible) {
    passwordField.attr("type", "text");
    icon.removeClass("fa-eye-slash");
    icon.addClass("fa-eye");
  } else {
    passwordField.attr("type", "password");
    icon.removeClass("fa-eye");
    icon.addClass("fa-eye-slash");
  }
};

$("#password-toggle").on("click", function () {
  showPassword = !showPassword;
  setPasswordFieldVisibility(showPassword, $("#developer_password"), $("#password-toggle"));
});

$("#confirm-password-toggle").on("click", function () {
  showConfirmPassword = !showConfirmPassword;
  setPasswordFieldVisibility(
    showConfirmPassword,
    $("#developer_password_confirmation"),
    $("#confirm-password-toggle")
  );
});
