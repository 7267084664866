/**
 * File that gets included in devise.html.erb and sets up some stuff on the
 * auth page.
 */

const $ = window.$;

// This is an extension of intl-tel-input js plugin customized for Braze and is used for two-factor-authentication.
// This adds validation and country codes for phone numbers.
const initializeIntlTelInput = (element) => {
  const $element = $(element);
  const $form = $element.closest("form");

  // Add a hidden field to store the country code
  $element.before(
    "<input type='hidden' name='country_code' id='intl-tel-input-country-code' type='tel' value='1'>"
  );

  // Initiate intlTelInput
  $element.intlTelInput({
    autoPlaceholder: false,
    // if utils.js gets updated, increment version number (?v3.0) to bust the cache.
    // http://twosixcode.com/notes/view/simple-cache-busting-for-css-and-js
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js",
  });

  // Add an event listener that changes the value of "country_code" when the user changes the country.
  $element.on({
    countrychange() {
      const dc = $element.intlTelInput("getSelectedCountryData").dialCode;
      return $("#intl-tel-input-country-code").val(dc);
    },
  });

  // Add an event listener on the form to validate the phone number format.
  $form.on({
    submit() {
      if (!$element.intlTelInput("isValidNumber")) {
        if ($form.has($(".error-msg")).length === 0) {
          $(".iti").after("<div class='error-msg'>This phone number is invalid.</div>");
        }
        return false;
      }
    },
  });
};

initializeIntlTelInput($("#phone"));

// Set the time zone to send with the login.  AS a general practice, this is probably the only place we should manually set tz
// - elsewhere we should use the preference on the Company that's set based on this to ensure consistent results
// between developers.
$("#time_zone").val(Intl.DateTimeFormat().resolvedOptions().timeZone);

// Focus on the first text input type on the form so it's easy to start typing.
// First try a "text" input box, if you can't find it, find an "email" text box.
let $firstInput = $("input[type=text]:first");

if ($firstInput.length === 0) {
  $firstInput = $("input[type=email]:first");
}

if ($firstInput.length > 0) {
  $firstInput.focus();
}
